import { useState, useContext, useEffect, useMemo, useCallback } from 'react';

import debounce from 'lodash/debounce';

import { LIST_STEP } from '@vyce/core/src/constants';
import { TeamsPageContext } from '@vyce/core/src/contexts';
import { OrderByItem } from '@vyce/core/src/types';

import { useTypedSelector } from '../../../hooks';
import { DEFAULT_TEAMS_LIST_SORTING } from '../config';
import { getTeamsListSortingObject } from '../utils';

export const useTeamsListData = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState<OrderByItem>(DEFAULT_TEAMS_LIST_SORTING);
  const [substring, setSubstring] = useState<string>('');
  const { teamsCount = 0, getTeams } = useContext(TeamsPageContext);
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const pageCount = Math.ceil(teamsCount / LIST_STEP);

  const paginationOptions = useMemo(
    () => ({
      page,
      pageSize: LIST_STEP,
      pageCount,
      rowCount: teamsCount,
      setPage,
    }),
    [page, pageCount, teamsCount]
  );

  const sort = useCallback((option: string) => {
    const sortData = getTeamsListSortingObject(option);
    setSorting(sortData);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubstring(e?.target?.value);
  }, []);

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 500), [handleSearchChange]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (!getTeams) return;

    const formattedPage = substring ? 0 : page;
    getTeams(formattedPage * LIST_STEP, substring, sorting);
  }, [substring, sorting, selectedCompany, page, getTeams]);

  return {
    substring,
    paginationOptions,
    handleSearchChange: debouncedSearchChange,
    wasSubscribed: selectedCompany?.subscribed_company,
    sort,
  };
};
