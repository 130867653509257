import React from 'react';

import { WorkerDetails } from '@vyce/core/src/views/time/WorkerDetails';
import { useEmployeeProfileData } from '@vyce/core/src/modules/userPreviewModule/hooks/useEmployeeProfileData';
import { getPassportRequest, getRtwRequest, getTeamMemberPreviewRequest } from '@vyce/core/src/api/teams';

import { useTypedSelector } from '../../hooks';

export const EmployerWorkerDetails: React.FC = () => {
  const { selectedCompanyAppData, selectedCompany, selectedPosition } = useTypedSelector(
    state => state.helper
  );
  const { userPreviewData, rtw, passport, userPreviewDataLoading } = useEmployeeProfileData({
    getEmployeePreviewRequest: getTeamMemberPreviewRequest,
    getPassportRequest,
    getRtwRequest,
    selectedCompany,
    selectedPosition,
  });

  return (
    <WorkerDetails
      loading={userPreviewDataLoading}
      companyId={selectedCompany?.uuid}
      locations={selectedCompanyAppData?.locations || []}
      rtw={rtw}
      passport={passport}
      userPreviewData={userPreviewData}
    />
  );
};
