import { useEffect, useState, useCallback, useContext } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import type { GridSortModel } from '@mui/x-data-grid';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

import { useForm } from 'react-hook-form';

import { Image, Team, TeamMember } from '@vyce/core/src/types';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import {
  ChangeTeamManagersData,
  CreateTeamData,
  GetCompanyTeamsMembersDataRequest,
} from '@vyce/core/src/api/types';
import {
  changeTeamManagersRequest,
  deleteLogoRequest,
  deleteTeamMembersRequest,
  deleteTeamRequest,
  getTeamMembersRequest,
  getTeamRequest,
  updateLogoRequest,
  updateTeamRequest,
  addTeamMembersRequest,
  getNonMembersRequest,
} from '@vyce/core/src/api/teams';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks';
import { isNil } from '@vyce/core/src/utils';
import { useTable } from '@vyce/core/src/hooks/useTable';

import { useTypedSelector } from '../../../hooks';

import { defaultValues } from '../config';

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const useTeamPageData = () => {
  // EmployeesForTransferList section
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<TeamMember[]>([]);
  const [transferTotal, setTransferTotal] = useState<number>(0);

  // dialogs section
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteTeamDialog, setOpenDeleteTeamDialog] = useState<boolean>(false);

  // requests section
  const [loading, setLoading] = useState<boolean>(false);
  const [teamData, setTeamData] = useState<Team>();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  // common section
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const [logo, setLogo] = useState<Image | undefined>(undefined);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);

  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    setOffset,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({ defaultSortModel });

  const { team } = useParams<{ team: string }>();
  const history = useHistory();
  const { selectedCompany } = useTypedSelector(state => state.helper);

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const pathname = history.location.pathname;
  const selectedCompanyId = selectedCompany?.uuid ?? '';
  const teamDataId = teamData?.uuid ?? '';
  const { handleServerError, showNotification } = useContext(NotificationContext);

  const methods = useForm<CreateTeamData>({
    defaultValues,
  });

  const handleSubmit = useCallback(
    async (data: CreateTeamData) => {
      if (!selectedCompanyId || !teamDataId) {
        return;
      }
      try {
        let newTeamData = null;
        const { logo, ...restData } = data;
        const restOldData = omit(teamData, ['uuid', 'logo']);
        const { logo: oldLogo } = teamData ?? {};
        const isEqualData = isEqual(restData, restOldData);
        const isEqualLogo = isEqual(logo, oldLogo);

        if (!isEqualData) {
          const { data } = await updateTeamRequest(selectedCompanyId, teamDataId, restData);
          newTeamData = data;
        }
        if (!isEqualLogo && logo) {
          const { data } = await updateLogoRequest(selectedCompanyId, teamDataId, logo);
          newTeamData = data;
        }

        setTeamData(newTeamData);
        showNotification({ message: 'Team has been updated', options: { variant: 'success' } });
      } catch (e) {
        handleServerError(e);
      }
    },
    [selectedCompanyId, teamDataId, teamData]
  );

  const changeManager = useCallback(
    async (id: string, isRemove: boolean) => {
      if (!selectedCompanyId || !teamDataId) {
        return;
      }
      try {
        const requestData: ChangeTeamManagersData = {
          to_add: [],
          to_remove: [],
        };
        if (isRemove) {
          requestData?.to_remove?.push(id);
        } else {
          requestData?.to_add?.push(id);
        }
        await changeTeamManagersRequest(selectedCompanyId, teamDataId, requestData);
        resetCloseStatus();
        await getTeamMembers();
      } catch (e) {
        handleServerError(e);
      }
    },
    [selectedCompanyId, teamDataId]
  );

  const getTeamMembers = useCallback(async () => {
    if (!selectedCompanyId || isNil(dOffset)) {
      return;
    }
    setLoading(true);
    const { id: teamId } = getUrlItems(team);
    try {
      const requestData: GetCompanyTeamsMembersDataRequest = {
        offset: dOffset as number,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatSortModel<TeamMember>(sortModel),
      };

      const {
        data: { items, count },
      } = await getTeamMembersRequest(selectedCompanyId, teamId, requestData);
      setTeamMembers(items);
      setTotal(count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId, sortModel, substring, dOffset, team]);

  const getTeam = useCallback(async () => {
    const teamUrlItems = getUrlItems(team);
    const teamId = teamUrlItems?.id;
    if (!selectedCompanyId || !teamId) {
      return;
    }
    try {
      const { data: teamData } = await getTeamRequest(selectedCompanyId, teamId);
      setTeamData(teamData);
      if (teamData?.logo) {
        setLogo({
          url: teamData.logo.url,
          file: null,
        });
      }
      methods.setValue('name', teamData.name);
      methods.setValue('logo', teamData.logo);
      methods.setValue('description', teamData?.description ?? '');
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId, team, methods]);

  const deleteTeam = useCallback(async () => {
    if (!selectedCompanyId || !teamDataId) {
      return;
    }
    try {
      setLoading(true);
      await deleteLogoRequest(selectedCompanyId, teamDataId);
      await deleteTeamRequest(selectedCompanyId, teamDataId);
      setLoading(false);
      history.push('/teams/list');
    } catch (e) {
      handleServerError(e);
      setLoading(false);
    }
  }, [history, selectedCompanyId, teamDataId]);

  const deleteTeamMembers = useCallback(
    async (member: string) => {
      if (!selectedCompanyId || !teamDataId) {
        return;
      }
      try {
        setLoading(true);
        const requestData = { members: [member] };
        await deleteTeamMembersRequest(selectedCompanyId, teamDataId, requestData);
        const newTeamMembers = teamMembers.filter(item => item.user_id !== member);
        setTeamMembers(newTeamMembers);
        setOffset(0);
        setLoading(false);
      } catch (e) {
        handleServerError(e);
        setLoading(false);
      }
    },
    [teamMembers, selectedCompanyId, teamDataId]
  );

  const handleTransfer = useCallback(async () => {
    if (!selectedCompanyId || !teamDataId) {
      return;
    }
    try {
      const enrichedModel = selectionModel.map(item => ({
        user_id: item,
      }));
      const requestData = { members: enrichedModel };
      await addTeamMembersRequest(selectedCompanyId, teamDataId, requestData);
      await getTeamMembers();
      setOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  }, [selectedCompanyId, teamDataId, selectionModel, getTeamMembers]);

  const getNonMembers = useCallback(
    async ({
      offset,
      substring,
      sortModel,
    }: {
      offset: number;
      substring: string;
      sortModel: GridSortModel;
    }) => {
      if (!selectedCompanyId || !teamDataId) {
        return;
      }
      try {
        setTransferLoading(true);

        const requestData: GetCompanyTeamsMembersDataRequest = {
          offset,
          limit: GRID_PAGE_SIZE,
          substring,
          order_by: formatSortModel<TeamMember>(sortModel),
        };
        const {
          data: { count, items },
        } = await getNonMembersRequest(selectedCompanyId, teamDataId, requestData);

        setTransferTotal(count);
        setEmployees(items);
        setTransferLoading(false);
      } catch (e) {
        setTransferLoading(false);
        handleServerError(e);
      }
    },
    [teamDataId, selectedCompanyId]
  );

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  useEffect(() => {
    getTeamMembers();
  }, [getTeamMembers]);

  useEffect(() => {
    getTeam();
  }, []);

  return {
    open,
    logo,
    total,
    loading,
    sortModel,
    teamMembers,
    substring,
    methods,
    teamData,
    pathname,
    handleSearchChange,
    openDeleteTeamDialog,
    closeGridAction,
    selectionModel,
    selectedCompanyId,
    teamId: teamDataId,
    transferLoading,
    transferTotal,
    employees,
    setOffset,
    handleSortModelChange,
    handleSubmit,
    handlePageChange,
    setOpen,
    setLogo,
    setOpenDeleteTeamDialog,
    deleteTeam,
    getTeamMembers,
    deleteTeamMembers,
    changeManager,
    handleTransfer,
    setSelectionModel,
    getNonMembers,
  };
};
