import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Box, MenuItem, MenuList } from '@material-ui/core';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { FilterSection, PayrollPayload, Timesheet } from '@vyce/core/src/types';
import { currencyFormatter, isNil } from '@vyce/core/src/utils';
import {
  deleteTimesheetRequest,
  generateNextPayrunRequest,
  getNextPayPeriodRequest,
  getTimesheetsRequest,
} from '@vyce/core/src/api/pay';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import {
  AppDataGridWithSavedPage,
  FilterSystem,
  AppLink,
  GridActions,
  ConfirmDialog,
  AccentNumbers,
} from '@vyce/core/src/components';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { CreateNewTimesheet, StatusComponents } from '@vyce/core/src/views/payroll/components';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useTable } from '@vyce/core/src/hooks/useTable';

import { useTypedSelector } from '../../hooks';

const defaultSortModel: GridSortModel = [{ field: 'created', sort: 'desc' }];

export const Timesheets: React.FC = () => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>();
  const [openCreateTimesheetDialog, setOpenCreateTimesheetDialog] = useState<boolean>(false);
  const { selectedCompany, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const { user } = useTypedSelector(state => state);

  const history = useHistory();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [timesheetToDelete, setTimesheetToDelete] = useState<Timesheet>();
  const { sortModel, offset, total, setTotal, setOffset, handleSortModelChange, handlePageChange } =
    useTable({ defaultSortModel });

  const { paySchedules = [] } = selectedCompanyAppData || {};

  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const enrichedTimesheets = useMemo(() => timesheets.map((item, i) => ({ ...item, id: i })), [timesheets]);

  const isAvailableToDelete = (pay_schedule_id: string) => {
    const payScheduleTimesheets = timesheets.filter(
      timesheet => timesheet.pay_schedule_id === pay_schedule_id
    );
    return payScheduleTimesheets?.length > 1;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink
            to={`/payroll/timesheets/${params.row?.name}_${params.row?.pay_run_id}_${selectedCompany?.uuid}`}>
            {params.row?.name}
          </AppLink>
        </Box>
      ),
    },
    {
      field: 'week',
      headerName: 'Week',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 90,
      renderCell: params => (
        <Box height={30} lineHeight="22px">
          <AccentNumbers typographyVariant="caption" label={params.row.week} variant="secondary" />
        </Box>
      ),
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      flex: 1,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 110,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: params => (
        <StatusComponents status={params.row.status} isGreen={params.row.status === 'submitted'} />
      ),
      flex: 1,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 90,
    },
    {
      field: 'submitted_by',
      headerName: 'Submitted By',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row: { submitted_by } }) => (
        <Box display="flex" width="100%">
          <AppLink
            target="_blank"
            to={
              user.uuid === submitted_by.uuid
                ? '/profile'
                : `/user-management/payroll/${submitted_by.first_name}_${submitted_by.uuid}`
            }>
            {submitted_by.first_name} {submitted_by.last_name}
          </AppLink>
        </Box>
      ),
      minWidth: 120,
    },
    {
      field: 'modified',
      headerName: 'Modified',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.modified, true),
      minWidth: 120,
    },
    {
      field: 'created',
      headerName: 'Created',
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.created, true),
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'workers',
      headerName: 'Workers',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'gross',
      headerName: 'Gross',
      disableColumnMenu: true,
      valueFormatter: params => currencyFormatter.format(params.row.gross),
      flex: 1,
      minWidth: 120,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: true,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <Box display="flex" width="100%">
            <GridActions close={closeGridAction}>
              <MenuList>
                <MenuItem onClick={() => generateNextPayrun(params.row.pay_schedule_id as string, true)}>
                  Duplicate
                </MenuItem>
                <MenuItem
                  disabled={params.row.status === 'submitted'}
                  onClick={() => {
                    setTimesheetToDelete(params.row as Timesheet);
                    setOpenConfirmDialog(true);
                  }}>
                  Delete timesheet
                </MenuItem>
              </MenuList>
            </GridActions>
          </Box>
        );
      },
    },
  ];

  const filtersSections: FilterSection[] = [
    {
      title: 'Pay Schedule',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Pay Schedule',
          field: 'payScheduleName',
          values: paySchedules?.map(item => item.name),
          defaultValue: '',
        },
      ],
    },
  ];

  const getTimesheets = useCallback(async () => {
    if (!selectedCompanyId || isNil(dOffset)) {
      return;
    }
    const payload: PayrollPayload = {
      limit: GRID_PAGE_SIZE,
      offset: dOffset as number,
      order_by: formatSortModel<Timesheet>(sortModel),
      pay_schedule_name: filters?.payScheduleName || null,
    };
    try {
      setLoading(true);
      const res = await getTimesheetsRequest(selectedCompanyId, payload);
      setTotal(res.data.count);
      setTimesheets(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setTimesheets([]);
      handleServerError(e);
    }
  }, [selectedCompanyId, dOffset, filters, sortModel]);

  const getNextPayPeriod = async (payScheduleId: string): Promise<string> => {
    if (!selectedCompanyId) {
      return '';
    }
    try {
      const res = await getNextPayPeriodRequest(selectedCompanyId, payScheduleId);
      return res.data;
    } catch (e) {
      handleServerError(e);
      return '';
    }
  };

  const deleteTimesheet = async (payrunId: string, payScheduleId: string) => {
    if (!selectedCompanyId) {
      return;
    }
    if (!isAvailableToDelete(payScheduleId)) {
      return showNotification({
        message: 'You cannot delete last timesheet in Pay schedule',
        options: { variant: 'warning' },
      });
    }
    try {
      const res = await deleteTimesheetRequest(selectedCompanyId, payrunId);
      resetCloseStatus();
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      setTimesheetToDelete(undefined);
      getTimesheets();
      setOpenConfirmDialog(false);
    } catch (e) {
      resetCloseStatus();
      handleServerError(e);
    }
  };

  const generateNextPayrun = async (payScheduleId: string, copyPrevious: boolean, isRedirect?: boolean) => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const res = await generateNextPayrunRequest({
        companyId: selectedCompanyId,
        payScheduleId,
        copyPrevious,
      });
      const newTimesheet = res.data.timesheet;
      getTimesheets();
      showNotification({ message: 'Timesheet has been created', options: { variant: 'success' } });
      setOpenCreateTimesheetDialog(false);
      resetCloseStatus();
      if (isRedirect) {
        history.push(
          `/payroll/timesheets/${newTimesheet?.pay_schedule?.name}_${newTimesheet?.pay_run_id}_${selectedCompanyId}`
        );
      }
    } catch (e) {
      resetCloseStatus();
      handleServerError(e);
    }
  };

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  const handleDialogClose = () => {
    setOpenCreateTimesheetDialog(false);
  };

  useEffect(() => {
    getTimesheets();
  }, [getTimesheets]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Box marginRight={2}>
          <CreateNewTimesheet
            handleDialogClose={handleDialogClose}
            open={openCreateTimesheetDialog}
            selectedCompanyId={selectedCompany?.uuid}
            setOpen={setOpenCreateTimesheetDialog}
            generateNextPayrun={generateNextPayrun}
            paySchedules={paySchedules}
            getNextPayPeriod={getNextPayPeriod}
          />
        </Box>

        <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={enrichedTimesheets}
        loading={loading}
        columns={columns}
        rowCount={total}
        height="calc(100vh - 240px)"
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="timesheets"
        withProfileButton
        setOffset={setOffset}
      />

      {timesheetToDelete && (
        <ConfirmDialog
          handleClose={() => {
            setOpenConfirmDialog(false);
            setTimesheetToDelete(undefined);
          }}
          open={openConfirmDialog}
          confirmText="Yes, delete timesheet"
          cancelText="No, go back"
          title="Are you sure you want to delete this timesheet?"
          handleConfirm={() =>
            deleteTimesheet(timesheetToDelete.pay_run_id, timesheetToDelete.pay_schedule_id)
          }
        />
      )}
    </>
  );
};
